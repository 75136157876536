<template lang="pug">
    el-breadcrumb(separator="/").app-breadcrumb
        transition-group(name="breadcrumb")
            el-breadcrumb-item(v-for="(item,index) in levelList" v-if="item.meta.title" :key="item.path")
                span(v-if="item.redirect==='noredirect'||index==levelList.length-1").no-redirect {{ item.meta.title }}
                router-link(v-else :to="item.redirect||item.path") {{ item.meta.title }}

</template>

<script>
const pathToRegexp = require("path-to-regexp");

export default {
  data() {
    return {
      levelList: null
    };
  },
  watch: {
    // $route 变，导航就变
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      const { params } = this.$route;
      let matched = this.$route.matched.filter(item => {
        if (item.name) {
          // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
          // TO-DO: 不明白，需要继续了解这个问题
          var toPath = pathToRegexp.compile(item.path);
          item.path = toPath(params);
          return true;
        }
      });

      //- 如果第一个路径不是首页，就加回首页到面包屑中
      const first = matched[0];
      if (
        first &&
        first.name.trim().toLocaleLowerCase() !==
          "Dashboard".toLocaleLowerCase()
      ) {
        matched = [{ path: "/dashboard", meta: { title: "首页" } }].concat(
          matched
        );
      }
      this.levelList = matched;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
